body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  overflow: hidden;
}

.demoPage {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.demoPage > canvas {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap; /* Allows the buttons to wrap on smaller screens */
}

.controls button {
  margin: 10px 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #000000; /* Black background */
  color: white; /* White text */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.controls button:hover {
  background-color: #333333; /* Slightly lighter black on hover */
  transform: scale(1.05); /* Slightly enlarge the button on hover */
}

.controls button:active {
  background-color: #1a1a1a; /* Even darker black on click */
  transform: scale(0.98); /* Slightly shrink on click */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .controls button {
    padding: 8px 16px; /* Reduce padding */
    font-size: 14px; /* Slightly smaller font size */
  }

  .demoPage {
    width: 90%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .controls button {
    padding: 6px 12px; /* Further reduce padding */
    font-size: 12px; /* Smaller font size for very small screens */
  }

  .controls {
    margin-top: 10px;
  }

  .demoPage {
    width: 100%;
    height: auto;
  }
}
